<template>
  <!-- Left div because b-navbar does not trigger on mouseenter / mouseleave events -->
  <div :data="backgroundColor"
       class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white overflow-hidden">
    <div ref="sidebarScrollArea" class="scrollbar-inner">
      <div v-if="!isCheckOutOfOffice" class="sidenav-header d-flex align-items-center">
        <b-navbar-brand>
          <logo/>
        </b-navbar-brand>
        <div class="mx-auto">
          <!-- Sidenav toggler -->
          <div :class="{'active': !$sidebar.isMinimized }"
               class="sidenav-toggler d-block"
               @click="minimizeSidebar">
            <!-- <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div> -->
          </div>
        </div>
      </div>
      <div v-if="isCheckOutOfOffice" class="sidenav-header d-flex align-items-center out-of-office-header">
        <b-navbar-brand>
          Out of Office
        </b-navbar-brand>
        <div class="mx-auto">
          <!-- Sidenav toggler -->
          <div :class="{'active': !$sidebar.isMinimized }"
               class="sidenav-toggler d-block"
               @click="minimizeSidebar">
          </div>
        </div>
      </div>
      <div v-if="!this.$sidebar.isMinimized && userId
      && twilioNumberFormatted && !isCheckOutOfOffice" :class="['twilio-number primary-color text-center', { 'mb-4': !isNumberLinking && !isNumberLinked }]">
        <template v-if="userId">
          <template v-if="twilioNumberFormatted">
            {{ twilioNumberFormatted }}
          </template>
        </template>
      </div>
      <div v-if="isNumberLinking || isNumberLinked" class="text-center mb-4">
        <span v-if="isNumberLinking" @click="showNumberLinkingModal">
          <b-badge variant="primary" :pill="true" class="activating-badge">Activating</b-badge>
        </span>
        <span v-if="isNumberLinked">
          <b-badge variant="primary" :pill="true" class="active-badge">Active</b-badge>
        </span>
      </div>
      <slot></slot>
      <div :class="{'number': userId && twilioNumberFormatted}" class="navbar-inner d-flex flex-column">
        <b-navbar-nav class="flex-fill">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </b-navbar-nav>
        <slot name="links-after"></slot>
      </div>
    </div>
    <invite-myself-modal ref="inviteMyselfModal"></invite-myself-modal>
    <number-linking-modal
      ref="numberLinkingModal"
    />
  </div>
</template>
<script>
import Logo from '@/components/App/UI/Logo';
import {mapActions, mapGetters} from 'vuex';
import InviteMyselfModal from '@/views/Dashboard/team/InviteMyselfModal.vue';
import NumberLinkingModal from '@/components/App/modals/NumberLinkingModal.vue';
import {accountTypes,vonageNumberLinkStatus} from '@/store/userConstants';
import {CHECK_LINK_STATUS} from '/src/store/storeActions';

export default {
  name: 'sidebar',
  components: {
    Logo,
    InviteMyselfModal,
    NumberLinkingModal,
  },
  data: function() {
    return {
      isOutOfOffice: false,
      isCheckOutOfOffice: false,
      isNumberLinking: false,
      isNumberLinked: false,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title',
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title',
    },
    logo: {
      type: String,
      default: '/img/brand/main.svg',
      description: 'Sidebar app logo',
    },
    backgroundColor: {
      type: String,
      default: 'primary',
      validator: (value) => {
        const acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        'List of sidebar links as an array if you don\'t want to use components for these.',
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  computed: {
    ...mapGetters([
      'userId',
      'twilioNumberFormatted',
      'outOfOfficeMessageEnabled',
      'accountType',
      'linkStatus',
    ]),
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  watch: {
    outOfOfficeMessageEnabled: {
      handler: function(value) {
        this.isOutOfOffice = value;
        this.checkOutOfOffice();
      },
      immediate: true,
    },
    linkStatus: {
      handler: function(value) {
        this.checkNumberLinking(value);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([CHECK_LINK_STATUS]),
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    resizeWindowHandler() {
      if ((window.innerWidth < this.$sidebar.breakpoint) && !this.$sidebar.isMinimized) {
        this.$sidebar.displaySidebar(false);
      }
    },
    showInvitationModal: function() {
      this.$refs.inviteMyselfModal.displayToggle(true);
    },
    checkOutOfOffice: function() {
      if (this.accountType === accountTypes.TEAM && this.isOutOfOffice) {
        this.isCheckOutOfOffice = true;
      } else {
        this.isCheckOutOfOffice = false;
      }
    },
    showNumberLinkingModal() {
      this.$refs.numberLinkingModal.openModal();
    },
    checkNumberLinking(numberLinkStatus) {
      if (numberLinkStatus && numberLinkStatus === vonageNumberLinkStatus.LINKING) {
        this.isNumberLinking = true;
        this.CHECK_LINK_STATUS();
      }

      if (numberLinkStatus && numberLinkStatus === vonageNumberLinkStatus.LINKED) {
        this.isNumberLinked = true;
      }
    },
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
    this.minimizeSidebar();
    window.addEventListener('resize', this.resizeWindowHandler);
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
    window.removeEventListener('resize', this.resizeWindowHandler);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/custom/_app-vars.scss";

.sidenav-header svg {
  width: 100%;
}

.navbar-inner {
  height: calc(100% - 80px);
  overflow: hidden;

  &.number {
    height: calc(100% - 140px);
  }
}

.out-of-office-header {
  background-image: linear-gradient(to right, #EA337F, #901CDD) !important;

  .navbar-brand {
    color: #fff !important;
    font-size: 1.5rem !important;
  }
}

.activating-badge {
  background-color: #D2008E;
  text-transform: none;
  cursor: pointer;
}

.active-badge {
  background-color: #8301AB;
  text-transform: none;
}
</style>
