import api from './axios';

export const findPhoneNumberApi = (areaCode) => {
  return api.get('/seat/findPhoneNumber', {
    params: {
      areaCode,
    },
  });
};

export const buyPhoneNumberApi = (payload) => {
  return api.post('/seat/buyPhoneNumber', payload);
};

export const enableLandlineApi = (payload) => {
  return api.post('/user/enableLandline', payload);
};

export const changePlanApi = (payload) => {
  return api.post('/seat/changePlan', payload);
};

export const buyTextPackageApi = (payload) => {
  return api.post('/seat/buyTextPackage', payload);
};

export const cancelPlanApi = () => {
  // test method will change later
  return api.post('/seat/cancelPlan');
};

export const releasePhoneNumber = (payload) => {
  return api.post('/seat/resetPhoneNumber', payload);
};

export const updateSeatTagsApi = (seatId, payload) => {
  return api.put(`/seat/${seatId}/updateTags`, payload);
};

export const transferHiringManagerSeatApi = (seatId, payload) => {
  return api.put(`/seat/${seatId}/transferHiringManagerSeat`, payload);
};

export const getNewNumberApi = (seatId, payload) => {
  return api.put(`/seat/${seatId}/updatePhoneNumber`, payload);
};

export const checkLinkStatusApi = () => {
  return api.get(`/seat/checkLinkStatus`);
};

