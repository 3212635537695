<template>
  <div class="invite-member-modal">
    <modal
      :show="display"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
      v-on:close="closeModal"
    >
      <template v-slot:header>
        <h5 id="exampleModalLabel" class="modal-title">
          Getting your number ready
        </h5>
      </template>
      <div class="mt-2 mb-5">
        <div>Your new Keeyora number will be ready for texting soon. </br></br>
          If the status still shows as 'Activating' after 24 hours, please reach out to Keeyora team via the
          <contact-us text="Support tab" @on-close-modal="closeModal"/>.
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <base-button
            type="primary"
            @click.prevent="acceptHandler"
        >
          OK
        </base-button>
      </div>
    </modal>
  </div>
</template>
<script>
import ContactUs from '@/views/Dashboard/ContactUs.vue';

const eventAccept = new Event('accept-modal');

export default {
  components: {
    ContactUs,
  },
  data() {
    return {
      display: false,
    };
  },
  methods: {
    openModal() {
      this.display = true;
    },
    async openModalAsync() {
      this.display = true;
      return new Promise((resolve, reject) => {
        addEventListener('accept-modal', () => {
          this.removeListeners();
          resolve('accept');
        });

      });
    },
    removeListeners: function() {
      removeEventListener('accept-modal', eventAccept);
    },
    closeModal: function() {
      setTimeout(() => {
        this.display = false;
      });
      this.$emit('on-close');
      this.updateNumberLinkStatus();
    },
    acceptHandler: function() {
      dispatchEvent(eventAccept);
      this.$emit('on-accept');
      this.closeModal();
    },
    updateNumberLinkStatus() {
      this.$store.commit('SET_LINKING_NUMBER', {isLinkingNumber: false});
    },
  },
};
</script>
