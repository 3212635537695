<template>
  <a id="intercom" @click="openIntercom()">{{ text }}</a>
</template>

<script>
import {createHmac} from 'crypto';
import {mapGetters} from 'vuex';

export default {
  props: {
    text: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      'userId',
      'userEmail',
    ]),
  },
  methods: {
    generateUserHash: function() {
      const hmac = createHmac('sha256', process.env.VUE_APP_INTERCOM_SECRET_KEY);
      hmac.update(this.userId.toString());
      return hmac.digest('hex');
    },
    openIntercom() {
      this.$intercom.boot({
        user_id: this.userId,
        user_hash: this.generateUserHash(),
        email: this.userEmail,
        custom_launcher_selector: '#intercom',
      });
      // Add click event listener to close the Intercom when clicked outside
      this.addClickOutsideListener();
      this.$emit('on-close-modal');
    },
    addClickOutsideListener() {
      document.addEventListener('click', this.handleClickOutside);
    },
    // Function to handle click outside the Intercom
    handleClickOutside(event) {
      const intercomLauncher = document.querySelector('#intercom');
      if (!intercomLauncher.contains(event.target)) {
        this.closeIntercom();
      }
    },
    // Close Intercom and remove the outside click listener
    closeIntercom() {
      this.$intercom.hide();
      document.removeEventListener('click', this.handleClickOutside); // Remove listener after closing
    },
  },
};
</script>

<style lang="scss">
</style>
