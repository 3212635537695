// actions
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const RESET_PASSWORD_SEND_EMAIL_REQUEST = 'RESET_PASSWORD_SEND_EMAIL_REQUEST';
export const RESET_PASSWORD_USING_CODE_REQUEST = 'RESET_PASSWORD_USING_CODE_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const FETCH_AUTH_DATA_REQUEST = 'FETCH_AUTH_DATA_REQUEST';
export const FETCH_USER_REQUEST = 'FETCH_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL';
export const GET_USER_ANALYTICS = 'GET_USER_ANALYTICS';
export const GET_ACCOUNT_ANALYTICS = 'GET_ACCOUNT_ANALYTICS';
export const CANCEL_PLAN_REQUEST = 'CANCEL_PLAN_REQUEST';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const FETCH_SEATS_REQUEST = 'FETCH_SEATS_REQUEST';
export const BUY_PHONE_NUMBER_REQUEST = 'BUY_PHONE_NUMBER_REQUEST';
export const ENABLE_LANDLINE_REQUEST = 'ENABLE_LANDLINE_REQUEST';
export const FETCH_CREDIT_CARD_REQUEST = 'FETCH_CREDIT_CARD_REQUEST';
export const UPDATE_CREDIT_CARD_REQUEST = 'UPDATE_CREDIT_CARD_REQUEST';
export const FETCH_PAYMENTS_DATA_REQUEST = 'FETCH_PAYMENTS_DATA_REQUEST';
export const PURCHASE_PLAN_REQUEST = 'PURCHASE_PLAN_REQUEST';
export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATE_ATTRIBUTES_REQUEST = 'FETCH_TEMPLATE_ATTRIBUTES_REQUEST';
export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const FETCH_TEAM_TEMPLATES_REQUEST = 'FETCH_TEAM_TEMPLATES_REQUEST';
export const CREATE_TEAM_TEMPLATE_REQUEST = 'CREATE_TEAM_TEMPLATE_REQUEST';
export const UPDATE_TEAM_TEMPLATE_REQUEST = 'UPDATE_TEAM_TEMPLATE_REQUEST';
export const DELETE_TEAM_TEMPLATE_REQUEST = 'DELETE_TEAM_TEMPLATE_REQUEST';
export const FETCH_HM_TEMPLATES_REQUEST = 'FETCH_HM_TEMPLATES_REQUEST';
export const CREATE_HM_TEMPLATE_REQUEST = 'CREATE_HM_TEMPLATE_REQUEST';
export const UPDATE_HM_TEMPLATE_REQUEST = 'UPDATE_HM_TEMPLATE_REQUEST';
export const DELETE_HM_TEMPLATE_REQUEST = 'DELETE_HM_TEMPLATE_REQUEST';
export const FETCH_ATS_INTEGRATION_REQUEST = 'FETCH_ATS_INTEGRATION_REQUEST';
export const ATS_INTEGRATION_CONNECTION_REQUEST = 'ATS_INTEGRATION_CONNECTION_REQUEST';
export const ATS_INTEGRATION_OAUTH_REQUEST = 'ATS_INTEGRATION_OAUTH_REQUEST';
export const UPDATE_ATS_INTEGRATION_REQUEST = 'UPDATE_ATS_INTEGRATION_REQUEST';
export const DELETE_ATS_INTEGRATION_REQUEST = 'DELETE_ATS_INTEGRATION_REQUEST';
export const FETCH_PLANS_REQUEST = 'FETCH_PLANS_REQUEST';
export const PURCHASE_SEATS_REQUEST = 'PURCHASE_SEATS_REQUEST';
export const REMOVE_SEAT_REQUEST = 'REMOVE_SEAT_REQUEST';
export const DELETE_SEAT_FROM_ACCOUNT_REQUEST = 'DELETE_SEAT_FROM_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_CALL_FORWARDING_REQUEST = 'UPDATE_CALL_FORWARDING_REQUEST';
export const BUY_TEXT_PACKAGE_REQUEST = 'BUY_TEXT_PACKAGE_REQUEST';
export const FETCH_USER_TEXT_ANALYTICS_REQUEST = 'FETCH_USER_TEXT_ANALYTICS_REQUEST';
export const CHANGE_USER_TYPE_REQUEST = 'CHANGE_USER_TYPE_REQUEST';
export const RESET_NUMBER_REQUEST = 'RESET_NUMBER_REQUEST';
export const FETCH_INTERVIEW_REMINDERS_REQUEST = 'FETCH_INTERVIEW_REMINDERS_REQUEST';
export const UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST = 'UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST';
export const UPDATE_USER_INTERVIEW_REMINDER_REQUEST = 'UPDATE_USER_INTERVIEW_REMINDER_REQUEST';
export const FETCH_JOB_STAGES_REQUEST = 'FETCH_JOB_STAGES_REQUEST';
export const FETCH_OUT_OF_OFFICE_MSG_REQUEST = 'FETCH_OUT_OF_OFFICE_MSG_REQUEST';
export const UPDATE_OUT_OF_OFFICE_MSG_REQUEST = 'UPDATE_OUT_OF_OFFICE_MSG_REQUEST';
export const FETCH_USER_OFFICE_MSG_REQUEST = 'FETCH_USER_OFFICE_MSG_REQUEST';
export const UPDATE_USER_OFFICE_MSG_REQUEST = 'UPDATE_USER_OFFICE_MSG_REQUEST';
export const UPDATE_USER_TO_TEXTER = 'UPDATE_USER_TO_TEXTER';
export const UPDATE_SEAT_TAGS = 'UPDATE_SEAT_TAGS';
export const UPDATE_USER_ONBOARDING_REQUEST = 'UPDATE_USER_ONBOARDING_REQUEST';
export const CREATE_USER_ONBOARGING_REQUEST = 'CREATE_USER_ONBOARGING_REQUEST';
export const FETCH_USER_ONBOARDING_REQUEST = 'FETCH_USER_ONBOARDING_REQUEST';
export const UPDATE_TEMPLATE_ONBOARDING_REQUEST = 'UPDATE_TEMPLATE_ONBOARDING_REQUEST';
export const DELETE_ONBOARD_TEMPLATE_REQUEST = 'DELETE_ONBOARD_TEMPLATE_REQUEST';
export const UPDATE_USER_CALENDAR_REQUEST = 'UPDATE_USER_CALENDAR_REQUEST';
export const FETCH_USER_CALENDARS_REQUEST = 'FETCH_USER_CALENDARS_REQUEST';
export const CREATE_USER_CALENDAR_REQUEST = 'CREATE_USER_CALENDAR_REQUEST';
export const UPDATE_TEMPLATE_CALENDAR_REQUEST = 'UPDATE_TEMPLATE_CALENDAR_REQUEST';
export const DELETE_CALENDAR_TEMPLATE_REQUEST = 'DELETE_CALENDAR_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_ORDER_REQUEST = 'UPDATE_TEMPLATE_ORDER_REQUEST';
export const TRANSFER_HIRING_MANAGER_SEAT = 'TRANSFER_HIRING_MANAGER_SEAT';
export const GET_NEW_NUMBER = 'GET_NEW_NUMBER';
export const VERIFY_MFA = 'VERIFY_MFA';
export const SEND_MFA_EMAIL = 'SEND_MFA_EMAIL';
export const FETCH_SSO_URL_REQUEST = 'FETCH_SSO_URL_REQUEST';
export const CHECK_LINK_STATUS = 'CHECK_LINK_STATUS';

// mutations
export const LOADING_STATE_SET = 'LOADING_STATE_SET';
export const LOADING_TEAM_STATE_SET = 'LOADING_TEAM_STATE_SET';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const FETCH_AUTH_DATA_SUCCESS = 'FETCH_AUTH_DATA_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const RESET_PASSWORD_SEND_EMAIL_SUCCESS = 'RESET_PASSWORD_SEND_EMAIL_SUCCESS';
export const RESET_PASSWORD_USING_CODE_SUCCESS = 'RESET_PASSWORD_USING_CODE_SUCCESS';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const USER_ANALYTICS_SUCCESS = 'USER_ANALYTICS_SUCCESS';
export const ACCOUNT_ANALYTICS_SUCCESS = 'ACCOUNT_ANALYTICS_SUCCESS';
export const FETCH_SEATS_SUCCESS = 'FETCH_SEATS_SUCCESS';
export const FETCH_CREDIT_CARD_SUCCESS = 'FETCH_CREDIT_CARD_SUCCESS';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const CLEAR_PAYMENTS_DATA = 'CLEAR_PAYMENTS_DATA';
export const CLEAR_SEATS_DATA = 'CLEAR_SEATS_DATA';
export const CLEAR_PLANS_DATA = 'CLEAR_PLANS_DATA';
export const CLEAR_TEMPLATES_DATA = 'CLEAR_TEMPLATES_DATA';
export const CLEAR_TEAM_TEMPLATES_DATA = 'CLEAR_TEAM_TEMPLATES_DATA';
export const CLEAR_ATS_INTEGRATIONS = 'CLEAR_ATS_INTEGRATIONS';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATE_ATTRIBUTES_SUCCESS = 'FETCH_TEMPLATE_ATTRIBUTES_SUCCESS';
export const FETCH_TEAM_TEMPLATES_SUCCESS = 'FETCH_TEAM_TEMPLATES_SUCCESS';
export const FETCH_HM_TEMPLATES_SUCCESS = 'FETCH_HM_TEMPLATES_SUCCESS';
export const FETCH_ATS_INTEGRATION_SUCCESS = 'FETCH_ATS_INTEGRATION_SUCCESS';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const CHANGE_PLANS_COMPONENT_SCREEN = 'CHANGE_PLANS_COMPONENT_SCREEN';
export const FETCH_USER_TEXT_ANALYTICS_SUCCESS = 'FETCH_USER_TEXT_ANALYTICS_SUCCESS';
export const REINVITE_USER_ACTIVE = 'REINVITE_USER_ACTIVE';
export const FETCH_INTERVIEW_REMINDERS_SUCCESS = 'FETCH_INTERVIEW_REMINDERS_SUCCESS';
export const FETCH_JOB_STAGES_SUCCESS = 'FETCH_JOB_STAGES_SUCCESS';
export const CLEAR_INTERVIEW_REMINDERS_DATA = 'CLEAR_INTERVIEW_REMINDERS_DATA';
export const CLEAR_USER_OFFICE_MSG = 'CLEAR_USER_OFFICE_MSG';
export const FETCH_USER_OFFICE_MSG_SUCCESS = 'FETCH_USER_OFFICE_MSG_SUCCESS';
export const CLEAR_ONBOARDINGS_DATA = 'CLEAR_ONBOARDINGS_DATA';
export const FETCH_ONBOARDING_SUCCESS = 'FETCH_ONBOARDING_SUCCESS';
export const CLEAR_CALENDARS_DATA = 'CLEAR_CALENDARS_DATA';
export const FETCH_CALENDARS_SUCCESS = 'FETCH_CALENDARS_SUCCESS';
export const BUY_PHONE_NUMBER_SUCCESS = 'BUY_PHONE_NUMBER_SUCCESS';
export const SET_MFA_NEEDED = 'SET_MFA_NEEDED';
export const SET_LINKING_NUMBER = 'SET_LINKING_NUMBER';
